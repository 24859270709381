<template lang="pug">
  .date-pagination
    AppButton.to-start(
      skip-translation
      title="<<"
      :disabled="isSelectedFirstOption"
      @click="handleToStart"
    )
    AppButton.prev(
      skip-translation
      title="<"
      :disabled="isSelectedFirstOption"
      @click="handlePrev"
    )
    .selected-dates {{ selectedPeriodTitle }}
    AppButton.next(
      skip-translation
      title=">"
      :disabled="isSelectedLastOption"
      @click="handleNext"
    )
    AppButton.to-end(
      skip-translation
      title=">>"
      :disabled="isSelectedLastOption"
      @click="handleToEnd"
    )
</template>

<script>
  // misc
  import { head, last, indexOf } from "lodash-es"
  import { format as dateFormat } from "date-fns"
  import { DATE_FNS_SHORT_DATE_FORMAT } from "@/config/constants"

  export default {
    components: {
      AppButton: () => import("@/components/elements/AppButton")
    },

    props: {
      options: Array,
      value: Array
    },

    computed: {
      selectedPeriodTitle({ value, formatted }) {
        return `${formatted(head(value))} - ${formatted(last(value))}`
      },

      selectedPeriodIndex({ options, value }) {
        return indexOf(options, value)
      },

      optionsCount({ options }) {
        return options.length
      },

      isSelectedFirstOption({ selectedPeriodIndex }) {
        return selectedPeriodIndex === 0
      },

      isSelectedLastOption({ optionsCount, selectedPeriodIndex }) {
        return optionsCount === selectedPeriodIndex + 1
      }
    },

    methods: {
      formatted(date) {
        return dateFormat(date, DATE_FNS_SHORT_DATE_FORMAT)
      },

      handleToStart() {
        this.sendData(this.options[0])
      },

      handlePrev() {
        this.sendData(this.options[this.selectedPeriodIndex - 1])
      },

      handleNext() {
        this.sendData(this.options[this.selectedPeriodIndex + 1])
      },

      handleToEnd() {
        this.sendData(this.options[this.optionsCount - 1])
      },

      sendData(data) {
        this.$emit("change", data)
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"

  .date-pagination
    align-items: center
    background-color: $th-background-color
    border-radius: 5px
    color: $default-purple
    display: flex
    justify-content: space-between
    padding: 5px
    width: 200px

    ::v-deep
      .app-button
        color: $default-purple
        font-weight: 600
        padding: 5px

    .selected-dates
      text-align: center
      user-select: none
</style>
